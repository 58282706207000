.bulk-upload-button {
	background: #17a2b8;
	color: white;
	margin-left: 26px;
	height: 41px;
	border: 1px solid #1194a8;
	border-radius: 2px;
}

.bulk-upload-modal {
	max-width: 800px;
	min-height: 800px;
	margin: 0 auto;
}

.bulk-upload-input-div {
	min-height: 120px;
	min-width: 140px;
	width: fit-content;
	margin: 0 auto;
	padding: 10px 20px;
	border: 1px solid gray;
	border-radius: 4px;
	background-color: #c6c6c642;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.hide-react-table-footer .select-wrap{
    visibility: hidden;

}
